.board {
    -webkit-text-size-adjust: 100%;
    color: rgb(55, 53, 47);
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: auto;
    box-sizing: content-box;
    width: 460px;
    height: max-content;
    margin-left: 2rem;
    margin-top: 2rem;
    transition: .2s ease-in-out all;
    padding-bottom: 20vh;
}

@media only screen and (max-width: 600px) {
    .board {
        width: auto;
    }
}


.board h2 {
    font-weight: normal;
    font-size: 1rem;
}

.board header h1 {
    transition: .2s ease-in-out all;
    font-weight: 300;
    margin-bottom: 1.5rem;
    font-size: 0.8rem
}
.board h2 {
    transition: .2s ease-in-out all;
}


.board.focus .list-title h1,
.board.focus header h1 {
    opacity: 0.2;
}

.board.focus h2 {
    opacity: 0.2;
} 

.board.focus .card {
    /* pointer-events: none; */
    opacity: 0.2;

}

.board.focus .card.active,
.board.focus .card.focused {
    opacity: 1;
    /* pointer-events: all; */
}

.board header img {
    object-fit: cover; border-radius: 3px; width: 36px; height: 36px; transition: opacity 100ms ease-out 0s;
    margin-right: 0.6rem;
}

header {
    /* display: flex;
    flex-direction: row; */
}

header h1 {
    margin: 0;
    padding: 0;
    line-height: 1.1;
}

header h1 a {
    color: black;
    text-decoration: none;
}

.branch h2 {
    margin: 0;
}

.branch {
    margin-bottom: 1rem;
}

ul.breadcrumb {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    display: inline-flex;
    position: fixed;
    top: 2rem;
}

ul.breadcrumb li {
    margin-bottom: .4rem;
    margin-right: 1rem;
    color: rgba(55, 53, 47,.2);
}

ul.breadcrumb li a {
    text-decoration: none;
    color: rgba(55, 53, 47,.2);
}


ul.breadcrumb li a:hover {
    color: rgba(55, 53, 47,1);
}

.level-up a {
    color: black;
    text-decoration: none;
    font-size: 2rem;
    position: absolute;
    left: -3rem;
    top: 1rem;
    color: rgba(55, 53, 47,.5);
}

.board .cards {
    position: relative;
    margin-top: 6rem;
}

.add-first-child {
    font-size: 1rem;
}

.add-first-child a {
    text-decoration: none;
    color: rgba(64, 44, 27,.2);
}

.add-first-child:hover a {
    color: rgb(64, 44, 27);
}

.children a {
    text-decoration: none;
}

.board .cards h1 {
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    /* background: -webkit-linear-gradient(#ff2fd9, #00ff15);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    position: relative;
    height: 2rem;
    overflow: hidden;
}


.board .cards h1 .parent,
.board .cards h1 .current {
    display: block;
    transition: .2s all;
    position: absolute;
    top: 0;
    left: 0;
}

.board .cards h1 .parent {
    transform: translateY(-100%);
}

.board .cards h1:hover .parent {
    transform: translateY(0);
}

.board .cards h1:hover .current  {
    transform: translateY(100%);
}
/* 
.board .cards h1:hover .current {
    transform: translateY(100%);
} */
