nav {
  position: fixed;
  top: 1rem;
  right: 2rem;
  font-size: .8rem;
  z-index: 10;

}

nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul a {
  text-decoration: none;
  text-transform: lowercase;
  color: black;
  margin-right: 1.5rem;
}

nav ul a.active {
  font-weight: 500;
}

nav ul {
  display: none;
  background: white;
  z-index: 10;
}

nav.open ul {
  display: block;
  position: fixed;
  margin-top: 3rem;
  right: 0;
  width: 250px;
  padding: 2rem;
  border: 1px solid black;
}

nav .toggle,
nav .search-toggle {
  cursor: pointer;
  font-size: 1.8rem;
}

nav li:hover {
  text-decoration: underline;
}

nav .search-toggle {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

nav .user {
  position: absolute;
  width: 100px;
  right: 1.5rem;
  top: 0.75rem;
  margin-right: 3rem;
  text-align: right;
}