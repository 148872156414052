.category-select {
    position: absolute;
    bottom: -40px;
    right: -130px;
    z-index: 10;
}


.category-select ul {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 4px;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
    margin: 0;
    padding: 0;
    padding: .8rem;
}

.category-select ul li {
    list-style: none;
    list-style-type: none;
    /* display: inline; */
    margin-bottom: 0.2rem;
    transition: .2s all;
    cursor: pointer;
}

.category-select ul li:hover {
    transform: translateX(-5px)
}

.category-select ul li.red {
    background: rgba(201, 89, 89,1);
    color: white;
}


.category-select ul li.salmon {
    background: rgba(237, 152, 126,1);
    color: white;
}


.category-select ul li.buff {
    background: rgba(242, 219, 128,1);
    
}


.category-select ul li.green {
    background: rgba(0,255,0,.5);
}