.quick-nav-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255,255,255,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.quick-nav {
  /* position: absolute;
  top: 0;
  right: 5rem;
  top: 5rem; */
  border: 1px solid black;
  border-radius: 5px;
  width: 350px;
  height: 175px;
  padding: 1rem;
  box-sizing: border-box;
  height: 50vh;
  overflow: scroll;
  background: white;
}

.quick-nav input {
  width: 100%;
  height: 2rem;
  outline: none;
  border: 0;
  border-bottom: 1px solid black;
  font-size: 0.9rem;
  font-weight: 500;
  box-sizing: border-box;
}

.quick-nav .search-result {
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.quick-nav .search-result.focus {
  background-color: rgba(180,180,180,.2);
}

.quick-nav .item-container {
  margin-top: 1rem;

}