.add-card {
  width: 260px;
  /* margin-left: 70px; */
  /* opacity: 0.3; */
}

.board .cards.root  .add-card {
  margin-left: 0;
}

.add-card .card-input {
  display: none;
  width: 100%;
}

.add-card .card-input.open {
  display: block;
}

.add-card .card-input input {
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  border-radius: 4px;
  border: 0;
  font-size: 0.9rem;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: auto;
  font-weight: 500;
  color: rgb(55, 53, 47);
}

@media only screen and (max-width: 600px) {
  .add-card .card-input input {
    border: 1px solid rgb(220,220,220);

  }
}


.add-card {
  cursor: pointer;
  color: rgb(180,180,180);
}

.add-card:hover .label {
  color: rgb(90,90,90);
}

.board.focus .add-card {
  opacity: 0.2;
}

.add-card .label {
  text-align: center;
  margin-bottom: 0.5rem;
} 

