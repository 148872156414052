.tag.empty {
    background: rgba(220,220,220,.25);
    width: 38px;
    /* height: 5px; */
    opacity: 0;
}

.tag.empty:hover {
    opacity: 1;
}

.tags .tag {
    cursor: pointer;
}

.tag.red {
    background: rgba(201, 89, 89,1);
    color: white;
}


.tag.salmon {
    background: rgba(237, 152, 126,1);
    color: white;
}


.tag.buff {
    background: rgba(242, 219, 128,1);
    
}


.tag.green {
    background: rgba(0,255,0,.5);
}