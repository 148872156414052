.card-approval {
  display: none;
  position: absolute;
  top: 0;
  left: 80%;
  right: 0;
  bottom: 0;
  /* width: 100%; */
  height: 100%;
  /* background: rgba(255,255,255,.6); */
  border-radius: 4px;
  background: white;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

}

.card-approval.open {
  display: flex;
  align-items: center;
  justify-content: right;
}

.card-approval .check {
/* margin-right: 10%;
border-radius: 50%;
box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
height: 50px;
width: 50px; */
/* border: 7px solid rgba(0,0,0,.4); */

}

.card-approval .check svg {
  width: 50px;
}