.card {
  line-height: 1.5;
  width: 260px;
  min-height: 80px;
  position: relative;
  line-height: 1.5;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: auto;
  font-weight: 500;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  text-decoration: none;
  user-select: none;
  transition: background 100ms ease-out 0s;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 4px 1px, rgba(15, 255, 15, 0.2) 4px 4px 4px;
  border: 1px solid rgb(220,220,220);
  border-radius: 4px;
  background: white;
  margin-bottom: 5px;
  margin-bottom: 1rem;
  font-size: .9rem;
  transition: opacity .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transform-origin: center left;
  /* background: #f5f5dc36; */
}

.card .card-options {
  opacity: 0;
  transition: .35s all;
}

.card:hover .card-options {
  opacity: 1;
}



.board .card-wrapper.active-target .card {
  background: rgba(0,255,0,.35);
}

.board .card-wrapper:first-child {
  margin-left: 0;
}

.board .cards.root .card-wrapper {
  margin-left: 0px;
}
.board .cards.root .card-wrapper:first-child {
  margin-left: 0px;
}

.card:hover {
  /* background-color: rgba(55,53,47,0.03);
  background-position: left bottom; */
}

.card:hover .start {
  opacity: 1;
}

.card.active {
  transform: scale(3);
  z-index: 5; 
}

.card.focused {
  transform: scale(2);
  opacity: 1; 
}

.card .content {
  display: inline-block;
  outline: 0;
  text-decoration: none;
  user-select: none;
  padding: 0.7rem;
  margin-bottom: 2rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  word-wrap: break-word;
}

.card .content .text {
  outline: 0;
  text-decoration: none;
  user-select: none;
  width: 85%;
}


.card .footer {
  position: absolute;
  bottom: 0rem;
  width: 100%;
}

.card .footer .project {
  position: absolute;
  bottom: 0.5rem;
  left: 0.7rem;
  font-size: 12px;
  font-weight: normal;
  height: 18px;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 120%;
  color: rgb(64, 44, 27);
  border: 1px solid rgb(180,180,180);
  line-height: 1.4;
  background: white;
}

.card .footer .tags {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  z-index: 20;
}

.tag {
  height: 18px;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
  line-height: 120%;
  color: rgb(64, 44, 27);
  background: rgb(253, 236, 200);
  margin: 0px 0px 0px 0px;
  font-weight: normal;
  line-height: 1.4;
}

.card .footer .tags .tag.icon {
  background: none;
  font-size: 14px;
}

.tag.green {
  background-color: rgba(0,255,0,.35);
}

.start {
  width: 20px;
  position: absolute;
  right: 1rem;
  top: 35%;
  font-size: 2rem;
  line-height: 1;
  opacity: 0;
  transition: .4s ease-in-out opacity;
}

.card .time {

}

.card .time .time-estimate {
  position: absolute;
  top: 0.5rem;
  right: 0.7rem;
  width: 30px;
  height: 30px;
  text-align: right;
  font-size: 0.7rem;
}

/* .card .time .time-estimate {
  position: absolute;
  top: 0.5rem;
  right: 0.7rem;
  width: 30px;
  height: 30px;
  text-align: right;
  font-size: 0.7rem;
} */

.card .time .start-timer {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  right: 0.7rem;
  width: 30px;
  height: 30px;
  text-align: right;
  font-size: 0.7rem;
  z-index: 2;
}


.card .time .timer {
  display: none;
}

.card.active .time .time-estimate {
  display: none;
}

.card.active .time .timer {
  display: block;
}

.card .time .timer-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.time-display {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.card.active .feature-buttons-temp {
  display: none;
}

.feature-buttons-temp {
  /* display: none; */
  display: flex;
  position: absolute;
  bottom: 0.4rem;
  left: 45%;
  color: rgb(180,180,180);
  font-size: 0.8rem;
}

.feature-buttons-temp div {
  margin-right: 0.3rem;
}

.feature-buttons-temp div:hover {
  color: black;
}

.card .children {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  top: 0.25rem;
  font-size: 3rem;
  line-height: 1;
  opacity: .5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  filter: grayscale();
  transition: .2s all;
}

.card .children {
  z-index: 10
}
.card.has-children .children {
  z-index: 1
}


.card.has-children:hover .children {
  opacity: 1;
  filter: none;
}

.focus-toggle {
  position: absolute;
  bottom: 0.7rem;
  left: 0.7rem;
  font-size: 1.6rem;
  color: rgb(220,220,220);
  z-index: 10;
  opacity: .1;
  transition: .2s all;
  cursor: pointer;
}


.focus-toggle:hover {
  color: black;
  opacity: 1;
}

.card.active .focus-toggle {
  opacity: 1;
}

.card.active .focus-toggle .icon {
  border-color: white;
}

.focus-toggle .icon {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: white;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 20px 10px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  border: 1px solid black;
}

.focus-toggle:hover .icon {
  border-color: white;
}


.up-arrow {
  position: absolute;
  left: -3rem;
  top: 1rem;
  font-size: 2rem;
  opacity: 0;
  transition: .2s all;
  color: rgb(0,255,0);
  transform: rotate(90deg);
}

.card:hover .up-arrow {
  opacity: .7;
}

.down-arrow {
  position: absolute;
  right: -3rem;
  top: 1rem;
  font-size: 2rem;
  opacity: 0;
  transition: .2s all;
  color: rgb(0,255,0);
  transform: rotate(270deg); 
}

.card:hover .down-arrow {
  opacity: .7;
}

a.parent-link {
  text-decoration: none;
  color: black;
}

.link-to-children {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  color: rgb(64, 44, 27);
}

.link-to-children .child-count {
  margin-right: 0.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.7rem;
    padding: .1rem;
    font-weight: 500;
    background: rgba(150,220,150,.5);
    /* border: 1px solid black; */
    width: 1rem;
    height: 1rem;
    text-align: center;

}

.card .text a {
  color: rgb(64, 44, 27);
  text-decoration: none;
}

.full-card-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.edit-icon img {
  width: 11px;
  margin-left: 0.3rem;
  opacity: 0.2;
}

.edit-icon:hover img {
  opacity: 0.7;
}

.edit-text textarea {
  margin: 0;
  padding: 0;
  border: 0;
  background: white;
  line-height: 1.5;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: .9rem;
  font-weight: 500;
  -webkit-font-smoothing: auto;
  outline: none;
  resize: none;
  color: rgb(64, 44, 27);
}


.card.status-done .text,
.card.status-done .children,
.card.status-done .footer,
.card.status-done .feature-buttons-temp {
  /* opacity: .2; */
}

.card.status-done .text {
  text-decoration-line: line-through;
  text-decoration-thickness: 4px;
  text-decoration-color: black
  /* color: rgba(64, 44, 2,.5); */
}

.card.status-done .tags {
  display: none;
}

.card.status-done .feature-buttons-temp {
  display: none;
}

.tray {
  position: absolute;
  right: -4rem;
  top: 0;
  bottom: 0;
  background: white;
  /* border: 1px solid black; */
  border-radius: 4px;
  width: 20%;
  padding: .5rem;
  box-sizing: border-box;
  z-index: 21;
  /* box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px; */
  display: flex;
  display: none;
}

.tray ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.change-status {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  opacity: 0;
  filter: grayscale();
  z-index: 25;
  transition: .2s all;
  cursor: pointer;
}

.change-status:hover {
  opacity: 1;
  filter: none;
}

.card.is-over {
  background: rgba(0,255,0,.05);
}


.editable-text {
  outline: none;
}